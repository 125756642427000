//AVAILABLE BESIDES MAINPAGE, LISTING, PRODUCT
// IMPORTS
@import (reference) "../settings.less";
@import (once) "forms.less";

.payment-channels-modal {

    .goshop-modal {
        width: 900px;

        .wrapper {
            .grid();
            grid-template-columns: repeat(6, minmax(0, 1fr));
            gap: 20px;
        }

        div:not(.wrapper) {
            cursor: pointer;
            border: 2px solid @border-color;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            height: 80px;
            border-radius: var(--borderRadius);
            position: relative;
            flex-direction: column;

            &.selected {
                border-color: @main-color;

                &:after {
                    content: '\e801';
                    font-family: icomoon;
                    position: absolute;
                    top: -13px;
                    right: 0;
                    left: 0;
                    margin: auto;
                    width: 24px;
                    height: 24px;
                    color: @total-color;
                    background-color: @main-color;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 15px;
                }
            }

            &:not(.selected):hover {
                border-color: @header-color;
            }
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.dpd-pickup-modal {

    .goshop-modal {
        width: 50vw !important;
        max-height: 90dvh !important;
        overflow: auto;
        background-color: #e6e7e8;

        @media (max-width: 1100px) {
            width: 90vw !important;
        }
    }

    .goshop-modal-body {
        padding: 0;
    }

    .goshop-modal-close {
        display: none;
    }
}

.dhl-pickup-modal {

    .goshop-modal {
        width: auto;
    }

    .goshop-modal-body {
        padding: 0;
    }
}

// NEWSLETTER LOGIN
.login-newsletter-row {
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
        display: flex;
        width: fit-content;
        align-items: center;

        input {
            display: none;

            &:checked + span span {
                background-color: @success-color;

                &:before {
                    transform: translateX(22px);
                }
            }
        }
    }

    label > span {
        height: 28px;
        position: relative;
        width: 50px;
        flex-shrink: 0;
        margin-left: 10px;
        display: block;

        span {
            background-color: #ccc;
            bottom: 0;
            cursor: pointer;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            transition: background-color .4s;
            border-radius: 17px;

            &:before {
                content: '';
                background-color: white;
                bottom: 4px;
                height: 20px;
                left: 4px;
                position: absolute;
                transition: .4s;
                width: 20px;
                border-radius: 50%;
            }
        }
    }
}

// USER ORDERS
.user-orders-summary {
    border: 1px solid @border-color;
    margin-bottom: 15px;
    display: flex;

    li {
        color: @header-color;
        padding: 10px;
        width: 50%;
        text-align: center;

        &:first-child {
            border-right: 1px solid @border-color;
        }

        span {
            color: @font-color;
        }

        strong {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 700;
        }
    }
}

.user-orders-list {
    margin-bottom: 15px;

    > li {
        border: 1px solid @border-color;

        &:not(:first-child) {
            border-top: 0;
        }
    }

    li:nth-child(2n) .order-header {
        background-color: @light-bg;
    }

    .order-header {
        padding: 15px;
        position: relative;
        overflow: hidden;

        h3 {
            width: fit-content;
            color: @header-color;
            font-size: 24px;

            small {
                font-size: 16px;
                font-weight: normal;
            }
        }

        em {
            font-style: normal;
            font-size: 16px;
            font-weight: normal;
        }

        span {
            position: relative;
            padding-left: 30px;

            b {
                display: inline-block;
                color: @header-color;
                font-weight: 700;
            }

            i {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
            }
        }

        .buttons-wrapper {
            position: absolute;
            top: 10px;
            right: 10px;
            height: ~"calc(100% - 20px)";
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        a {
            background-color: @main-color;
            color: white;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 0 30px;
            font-weight: 700;

            &:hover { 
                background-color: darken(@main-color, 10%);
            }
        }
    }
}

.order-buttons-wrapper {
    padding: 15px;
    padding-top: 10px;

    > strong {
        color: @header-color;
        font-weight: 700;
        margin-bottom: 5px;
        display: block;
    }

    .wrapper {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
    }

    .return-button {
        font-size: 14px;
        font-weight: normal;
        height: 32px;
        padding: 0 12px;
        border-radius: 0;
        border-width: 2px;
    }
}

.order-detailed-info {
    display: flex;
    clear: both;
    flex-flow: row wrap;
    border-top: 1px solid @border-color;

    > div {
        flex: 1;
        border-right: 1px solid @border-color;
        border-bottom: 1px solid @border-color;
        padding: 15px 10px 15px 15px;

        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6) {
            border-right: none;
        }

        &:nth-child(7) {
            border: none;
        }

        h4 {
            color: @header-color;
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 10px;
        }

        ul {
            list-style: none;

            li {
                padding: 2px 0;
            }
        }
    }

    .item-box-small {
        flex-basis: 33.33%;

        h4 {
            padding-left: 35px;
            position: relative;

            &:before {
                content: '';
                font-family: icomoon;
                color: @main-color;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 22px;
                text-align: left;
                font-size: 20px;
                font-weight: normal;
            }
        }

        &.user-data h4:before {
            content: '\e811';
            font-size: 26px;
        }

        &.user-address h4:before {
            content: '\e805';
        }

        &.user-history h4:before {
            content: '\e802';
            font-size: 25px;
        }

        &.user-history ul {
            list-style: disc;
            padding-left: 15px;
        }
    }

    .item-box-medium {
        flex-basis: 50%;

        h4 {
            padding-left: 35px;
            position: relative;

            &:before {
                content: '';
                font-family: icomoon;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                font-size: 22px;
                color: @main-color;
                font-weight: normal;
            }
        }

        p {
            display: block;
            color: @header-color;
            text-transform: none;
            font-weight: normal;
            font-size: var(--fontSize);
            margin-bottom: 10px;
        }

        ul {
            list-style: disc;
            padding-left: 15px;
        }

        ul li {
            padding: 0;
        }

        &.payment h4:before {
            content: '\e815';
            font-size: 24px;
        }

        &.shipment h4:before {
            content: '\e804';
        }
    }

    .item-box-large {
        flex-basis: 100%;

        .confirm-button {
            margin-top: 20px;
        }

        &.product-table {
            padding: 10px;
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        thead {
            background-color: @border-color;

            small {
                color: @font-color;
                font-size: 11px;
                position: relative;
            }
        }

        td, th {
            border: 1px solid @border-color;
            padding: 10px;

            a {
                color: @font-color;
                text-decoration: underline;
            }
        }

        th {
            color: @header-color;
            font-weight: 700;
            font-size: 16px;
        }

        tbody span {
            display: block;
            font-size: 12px;
            color: rgba(0,0,0,0.4);
        }

        tfoot {
            text-align: right;

            tr:first-child td {
                padding-top: 10px;
            }

            td {
                border: none;
                padding: 2px 10px;

                strong {
                    color: @header-color;
                    font-weight: 700;
                }

                b {
                    color: @header-color;
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }
    }
}

// SAVED CART
.single-saved-cart {
    position: relative;
    padding: 15px;
    border: 1px solid @border-color;
    border-bottom: none;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .buttons-wrapper {
        height: ~"calc(100% - 20px)";
        display: flex;
        gap: 10px;
        margin-top: 10px;

        a, i {
            background-color: @main-color;
            color: white;
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 3px 30px;
            font-weight: 700;
            cursor: pointer;

            &:hover { 
                background-color: darken(@main-color, 10%);
            }
        }

        i {
            font-style: normal;
        }

        .delete {
            background-color: @error-color;

            &:hover { 
                background-color: darken(@error-color, 10%);
            }
        }
    }

    &:last-child {
        border-bottom: 1px solid @border-color;
        margin-bottom: 30px;
    }

    > span {
        color: @font-color;
        font-weight: normal;

        b {
            color: @header-color;
            font-weight: 700;
        }
    }

    .ul-flex-wrapper {
        display: none;
        width: 100%;
        clear: both;

        > ul {
            display: flex;
            flex-direction: column;

            .summary-position {
                padding-top: 20px;
                min-height: 120px;
                position: relative;
                padding-left: 110px;

                figure {
                    width: 100px;
                    height: 100px;
                    justify-content: flex-start;
                    position: absolute;
                    top: 20px;
                    left: 0;

                    i {
                        color: white;
                        background-color: @error-color;
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 0 5px;
                        font-style: normal;
                    }
                }

                > strong {
                    color: @header-color;
                    font-weight: 700;
                    display: block;
                }

                ul {

                    strong {
                        font-weight: 700;
                        color: @header-color;
                    }
                }
            }
        }
    }
}

.shop-buttons-wrapper {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

// THREADS
.thread-header {
    padding: 15px;
    position: relative;
    overflow: hidden;
    border: solid 1px @border-color;

    em {
        font-style: normal;
        font-size: 16px;
        font-weight: normal;
    }

    span {
        float: left;
        clear: left;
        padding-bottom: 5px;

        b {
            display: inline-block;
            color: @header-color;
            font-weight: 700;
        }
    }
}

.thread-messages {
    margin-top: -1px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    border: solid 1px @border-color;
    display: flex;
    flex-direction: column-reverse;

    .message {
        margin-bottom: 30px;

        header {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            gap: 10px;

            i {
                width: 20px;
                height: 20px;
                margin: 0;
                font-style: normal;
                background-image: url("../../assets/images/favicon/favicon.png");
                background-size: contain;
            }

            .message-name {
                color: @header-color;
                font-weight: normal;
                font-size: 16px;
            }

            .message-time {
                font-size: 14px;
            }
        }

        .message-body {
            width: 80%;
            border-radius: 5px;
            padding: 15px;
            background-color: @light-bg;
            position: relative;
            color: @header-color;
            display: flex;
            flex-wrap: wrap;
            gap: 4px;

            &:after {
                position: absolute;
                top: -12px;
                left: 22px;
                content: " ";
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 12.5px 12px 12.5px;
                border-color: transparent transparent @light-bg transparent;
            }

            a {
                color: @main-color;
                text-decoration: underline;

                &:hover {
                    text-decoration: auto;
                }
            }
        }

        &.client-response {

            header {
                flex-direction: row-reverse;

                i {
                    background-image: none;

                    &:before {
                        color: @header-color;
                        content: "\e811";
                        font-family: icomoon;
                        font-size: 18px;
                        line-height: 18px;
                    }
                }
            }

            .message-body {
                float: right;
                background-color: #86BB71;
                color: #fff;

                &:after {
                    left: unset;
                    right: 22px;
                    border-color: transparent transparent #86BB71 transparent;
                }

                a {
                    color: @total-color;
                }
            }
        }
    }
}

.thread-response {
    margin-top: -1px;
    padding: 15px;
    position: relative;
    overflow: hidden;
    border: solid 1px @border-color;
}

// USER COMMENTS
.user-comment-header {
    border: 1px solid @border-color;
    padding: 15px;

    .headline {
        margin-bottom: 0;
    }

    h3 {
        color: @header-color;
        font-size: 24px;

        small, em {
            font-weight: normal;
            font-size: 16px;
            font-style: normal;
        }
    }
}

.products-to-comment-wrapper {
    display: flex;
    flex-direction: column;

    .single-comment-row ~ .infobox {
        display: none;
    }

    .infobox {
        margin-top: 30px;
    }
}

.single-comment-row {
    display: flex;
    padding: 30px 0;

    @media (max-width: 1100px) {
        flex-direction: column;
        gap: 20px;
        align-items: center;

        .single-comment-product-info {
            padding: 0;
        }

        form {
            width: 100%;
        }

        .comment-ok-message {
            font-size: 16px;
        }
    }

    &.product-commented {

        form {
            display: none;
        }

        .comment-ok-message {
            display: flex;
        }
    }
    
    &:not(:first-child) {
        border-top: 1px solid @border-color;
    }

    form {
        padding: 0;
        flex-grow: 1;

        &:after {
            display: none;
        }
    }
}

.comment-ok-message {
    display: none;
    align-items: center;
    flex-grow: 1;
    color: @success-color;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: .03em;

    &:before {
        content: '\e801';
        font-family: icomoon;
        font-weight: normal;
        font-size: 40px;
        margin-right: 10px;
    }
}

.single-comment-product-info {
    width: 350px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-right: 30px;

    strong {
        color: @header-color;
        text-align: center;
    }

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
    }
}

.comment-input-wrapper {
    position: relative;
    padding-left: 144px;
    overflow: hidden;
    margin-bottom: 22px;

    label {
        position: absolute;
        left: 0;
        top: 0;
        color: @header-color;
    }

    .stars-rating li {
        cursor: pointer;
        transform-origin: center bottom;

        &:hover {
            transform: scale(1.2);
        }
    }
}

// SITEMAP
.sitemap-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-block: 10px;
    width: 100%;
    gap: 20px;

    ul {
        display: flex;
        flex-direction: column;
        gap: 5px;
        list-style: disc;
        padding-bottom: 5px;
        flex: 1 1 0;
        min-width: 300px;

        &:before {
            content: attr(data-title);
            font-size: calc(var(--fontSize)  + 3px);
            font-weight: bold;
            color: @header-color;
        }

        li a {
            color: @font-color;

            &:hover {
                text-decoration: underline;
                color: @main-color;
            }
        }

        li::marker {
            color: @main-color;
            font-size: 1.1em;
        }

        li {
            margin-left: 15px;
        }
    }

    > ul {
        border: solid 1px @border-color;
        padding: 15px 15px 15px 15px;
        border-radius: var(--borderRadius);
    }
}
